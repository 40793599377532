import { Injectable, OnDestroy } from '@angular/core'
import { FeatureFlag } from '@feature-flag-lib/feature-flags.model'
import { LaunchDarklyService } from '@feature-flag-lib/launch-darkly.service'
import { DeveloperToolsService } from '@platform-lib/components/developer-tools/developer-tools.service'
import { combineLatest, Subscription } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService implements OnDestroy {
    private subscriptions = new Subscription()

    ready$ = this.launchDarklyService.ready$

    constructor(
        private launchDarklyService: LaunchDarklyService,
        private developerToolsService: DeveloperToolsService
    ) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    getFeatureFlag<TValue>(flag: FeatureFlag<TValue>) {
        return combineLatest([
            this.launchDarklyService.variation$(flag),
            this.developerToolsService.overriddenFeatureFlags$,
        ]).pipe(
            map(([launchDarklyValue, featureFlagOverrides]) => {
                const overriddenValue = featureFlagOverrides.find(
                    (override) => override.flag.name === flag.name
                )?.value

                return overriddenValue !== undefined
                    ? overriddenValue
                    : launchDarklyValue
            }),
            distinctUntilChanged()
        )
    }
}
