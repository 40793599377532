import { createFeatureFlag } from '@feature-flag-lib/createFeatureFlag'

export const FeatureFlags = {
    DashboardNewsWidget: createFeatureFlag('dashboard-news-widget', false),
    DeveloperTools: createFeatureFlag('developer-tools', false),
    ExtendedSensorTimeWindows: createFeatureFlag('sensor-time-windows', false),
    FastTrackFilter: createFeatureFlag('fast-track-filter', false),
    FastTrackWidget: createFeatureFlag('fast-track-widget', false),
    FleetOverviewRatingDialog: createFeatureFlag(
        'fleet-management-show-rating-popup',
        { enablePopup: false }
    ),
    GroupManagementGeofences: createFeatureFlag(
        'group-management-geofences',
        false
    ),
    Mixpanel: createFeatureFlag('mixpanel', false),
    MixpanelDebugMode: createFeatureFlag('mixpanel-debug-mode', false),
    SensorsExtendedTimeWindows: createFeatureFlag('sensor-time-windows', false),
    UserAnalytics: createFeatureFlag('user-analytics', false),
    WagonTrackingRatingPopup: createFeatureFlag('pathfinder-rating-popup', {
        enablePopup: false,
    }),
    WagonTrackingOnboardingPopup: createFeatureFlag(
        'pathfinder-onboarding-popup',
        false
    ),
    WagonTrackingCes: createFeatureFlag('wagon-tracking-ces', false),
}
