<header>
    <h4>Nutzungsbedingungen für die Plattform traigo</h4>
    <p class="subtitle">(Version 2.1 gültig ab 26.11.2024)</p>
    <p>
        Diese Nutzungsbedingungen regeln den Zugang zu und die Nutzung von der
        Internet-Plattform traigo ("<strong>Plattform</strong>"), die von der
        VTG Rail Europe GmbH ("<strong>VTG</strong>") bestimmten, ausgewählten
        gewerblichen Nutzern (jeweils "<strong>Nutzer</strong>", VTG oder der
        Nutzer jeweils auch eine "<strong>Partei</strong>" oder gemeinsam die
        "<strong>Parteien</strong>") zur Verfügung gestellt wird. Die Nutzung
        der Plattform unterliegt ausschließlich diesen Nutzungsbedingungen und
        den in diesen Nutzungsbedingungen ausdrücklich erwähnten weiteren
        Dokumenten.
    </p>
</header>

<section>
    <ol>
        <li class="li-h4">
            <h4>Gegenstand</h4>
            <ol>
                <li>
                    <p>
                        Gegenstand dieser Nutzungsbedingungen ist die Gewährung
                        von Zugang und entsprechenden Nutzungsrechten an der
                        Plattform, um den Nutzern die Nutzung der traigo-Tools
                        zu ermöglichen. Die traigo-Tools werden über die
                        Plattform angeboten. Darüber hinaus besteht die
                        Möglichkeit einer ITSS-Datenübertragung von Positions-
                        und Geofence-Daten in das System des Nutzers. Die
                        Konfiguration kann der jeweilige Kunden-Administrator
                        auf der traigo-Plattform vornehmen.
                    </p>
                </li>
                <li>
                    <p>
                        Die Plattform verfügt über die folgenden traigo-Tools:
                    </p>
                    <ul>
                        <li><p>Contract Management</p></li>
                        <li><p>Fleet Management</p></li>
                        <li><p>Wagon Tracking</p></li>
                        <li><p>ETA Calculation</p></li>
                        <li><p>Idle Times</p></li>
                        <li><p>Mileage Prediction</p></li>
                    </ul>
                    <p>
                        (gemeinsam mit weiteren Tools, die VTG nach seinem
                        eigenen Ermessen und gegebenenfalls unter ergänzenden
                        Nutzungsbedingungen und gegen Entgelt auf der Plattform
                        anbietet, die "<strong>traigo-Tools</strong>" werden,
                        wie im Folgenden näher definiert).
                    </p>
                    <p>
                        Die traigo-Tools sind für Waggons verfügbar, soweit der
                        Nutzer diese von VTG gemietet hat.Ferner sind die
                        traigo-Tools verfügbar für Waggons Dritter mit einem VTG
                        Connect Dienstleistungsvertrag, die der Nutzer von dem
                        Dritten gemietet hat. Außerdem sind die traigo-Tools
                        verfügbar für Waggons Dritter mit Connectoren Dritter,
                        die der Nutzer von dem Dritten gemietet hat, sofern der
                        Nutzer sicherstellt, dass die Dritten VTG die
                        erforderlichen Daten per ITSS zur Nutzung zur Verfügung
                        stellen.Das traigo-Tool Wagon Tracking ist nur
                        verfügbar, sofern der Nutzer einen entsprechenden VTG
                        Connect Vertrag abgeschlossen hat. Der Zugang zur
                        Plattform sowie die Nutzung der in Ziffer 2
                        dargestellten traigo-Tools ist für den Nutzer kostenlos
                        möglich (sofern nicht der Abschluss eines separaten
                        kostenpflichten Vertrages für einzelne traigo-Tools
                        erforderlich ist, z.B. ein VTG Connect Vertrag, siehe
                        unten Ziffer 2.3 und 2.4). Für einzeln zubuchbare
                        traigo-Tools können zusätzliche Kosten entstehen (siehe
                        unten Ziffer 3).
                    </p>
                </li>
                <li>
                    <p>
                        VTG ist jederzeit berechtigt, Änderungen an der
                        Plattform oder den traigo-Tools vorzunehmen oder diese
                        Bedingungen abzuändern, einschließlich der vollständigen
                        Einstellung des Plattform-Angebots.
                    </p>
                </li>
                <li>
                    <p>
                        Im Falle von erheblichen Änderungen an der Plattform
                        oder den traigo-Tools (d.h. solche Änderungen, die
                        bestehende traigo-Tools erheblich zulasten des Nutzers
                        einschränken oder ändern) oder diesen
                        Nutzungsbedingungen wird VTG den Nutzer mit einer Frist
                        von 15 Tagen hierüber informieren. Etwaige Änderungen
                        gelten verbindlich für die Nutzer, sofern diese nicht
                        innerhalb dieser Frist den Änderungen schriftlich
                        widersprechen oder die Plattform nach Ablauf der Frist
                        weiterhin nutzen. Auf diese Folge wird VTG den Nutzer
                        gemeinsam mit der Ankündigung der Änderung hinweisen.
                        Sollte der Nutzer der Änderung widersprechen, steht VTG
                        ein außerordentliches Kündigungsrecht für den Fall zu,
                        dass VTG die Plattform bzw. die traigo-Tools ohne die
                        Änderung nicht mehr mit vertretbarem Aufwand anbieten
                        kann.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>traigo-Tools</h4>
            <ol>
                <li>
                    <p>Vertragsmanagement</p>
                    <p>
                        Das Vertragsmanagement-Tool ermöglicht es dem Nutzer,
                        die Details des bzw. der mit VTG geschlossenen
                        Mietverträge über Waggons einzusehen. Die
                        Vertragsdetails beinhalten insbesondere die jeweilige
                        Vertragsdauer, enthaltene Waggons und den Mietpreis pro
                        Waggon. Die Darstellung dieser Daten erfolgt
                        ausschließlich auf Grundlage der bei VTG vorhandenen
                        Informationen. Die angezeigten Informationen sind
                        ausschließlich informatorischer Natur und haben keinen
                        Einfluss auf den Vertragsinhalt selbst. Der Kunde muss
                        die Richtigkeit der Angaben auf der Plattform daher in
                        jedem Fall prüfen und gegebenenfalls VTG auf etwaige
                        Fehler hinweisen. Etwaige Informationen oder
                        Nachrichten, die der Kunde VTG über die Plattform
                        zukommen lässt, entfalten folglich ebenfalls keine
                        rechtliche Wirkung hinsichtlich der Mietverträge.
                        Kündigungen, Vertragsverlängerungen oder ähnliche
                        Mitteilungen sind stets an den üblichen Ansprechpartner
                        bei VTG oder der jeweiligen VTG-Gesellschaft zu richten.
                    </p>
                </li>
                <li>
                    <p>Flottenmanagement</p>
                    <p>
                        Das Flottenmanagement-Tool ermöglicht es dem Nutzer,
                        verschiedene Informationen zu der vom Nutzer gemieteten
                        Flotte von Waggons einzusehen. Diese Informationen
                        beinhalten einen Überblick über die gesamte Flotte von
                        gemieteten Waggons, den Status einzelner Waggons (z.B.
                        Revisionsfälligkeit) sowie die Anzeige der jeweiligen
                        Kapazitäten. Ferner kann der Nutzer die gesamte von ihm
                        gemietete Waggon-Flotte nach bestimmten Kriterien
                        filtern (z.B. Wagenart, vereinbartes Transportgut). Die
                        Darstellung dieser Daten erfolgt ausschließlich auf
                        Grundlage der bei VTG vorhandenen Informationen. Die
                        angezeigten Informationen sind ausschließlich
                        informatorischer Natur und haben keinen Einfluss auf den
                        Vertragsinhalt selbst. Der Kunde muss die Richtigkeit
                        der Angaben auf der Plattform daher in jedem Fall prüfen
                        und gegebenenfalls VTG auf etwaige Fehler hinweisen.
                    </p>
                </li>
                <li>
                    <p>Wagenkarte</p>
                    <p>
                        Sofern und soweit der Nutzer einen VTG Connect Vertrag
                        über einzelne oder alle der von ihm gemieteten Waggons
                        abgeschlossen hat, kann er über das Wagonkarte-Tool die
                        von ihm angemieteten Waggons in (quasi) Echtzeit auf der
                        Plattform nachverfolgen. Der derzeitige Standort eines
                        konkreten Waggons kann über die Waggonnummer gefiltert
                        angezeigt werden. Dabei kann die Plattform nur die Daten
                        anzeigen, die von dem jeweiligen VTG Connector
                        übermittelt werden. Etwaige Störungen bei der
                        Datenübermittlung vom Waggon an das Netz von VTG fallen
                        dabei unter den jeweiligen VTG Connect Vertrag.
                    </p>
                </li>
                <li>
                    <p>Ankunftszeitprognose</p>
                    <p>
                        Dieses Tool ermöglicht eine Ankunftszeitprognose eines
                        Waggons an einem definierten Ziel. Hierzu werden die
                        vorhandenen Bewegungsdaten anderer Waggons, die mit
                        einem VTG Connector ausgerüstet sind, auf anonymisierter
                        Basis ausgewertet, um dem Nutzer eine Fahrzeitprognose
                        mit drei verschiedenen Ergebnissen (schnellste,
                        langsamste und durchschnittliche Fahrzeit) anzuzeigen.
                        Dieser Ergebnisse beruhen ausschließlich auf den
                        aufgezeichneten Fahrzeiten der Waggons in der
                        Vergangenheit, jeweils beruhend auf dem Monat, Tag und
                        Uhrzeit der Fahrt sowie weiteren Eigenschaften. Es
                        handelt sich hierbei folglich um Erfahrungswerte, die
                        auf dem zur Verfügung stehenden Datensatz beruhen. VTG
                        kann daher keine Gewähr für die Verlässlichkeit und
                        Richtigkeit der angezeigten Ergebnisse im Einzelfall
                        übernehmen.
                    </p>
                </li>
                <li>
                    <p>Standzeiten</p>
                    <p>
                        Das Tool hilft dem Nutzer dabei, die jeweiligen
                        Standzeiten für seine gesamte Waggon-Flotte auf
                        Einzelwagenbasis zu berechnen und somit die Auslastung
                        seiner Waggon-Flotte zu beurteilen. In dem Tool werden
                        nur solche Waggons des Nutzers angezeigt, für die der
                        Kunde einen noch laufenden VTG Connect Vertrag
                        abgeschlossen hat. Waggons, deren VTG Connector für
                        mindestens 72 Stunden am Stück nicht aktiv gesendet hat,
                        werden in dem Tool als inaktiv angezeigt. Etwaige
                        Störungen bei der Datenübermittlung vom Waggonan das
                        Netz von VTG fallen dabei unter den jeweiligen VTG
                        Connect Vertrag. Ein Waggon gilt als stehend, wenn er
                        sich in einem Zeitraum von sechs Stunden weniger als
                        10km bewegt hat. Um zu prüfen, ob ein Waggon als stehend
                        gilt, kann es daher sein, dass es bei der Berechnung der
                        Standzeiten zu Verzögerung von mind. sechs Stunden
                        kommt. Über das Tool kann sich der Kunde entweder die
                        jeweiligen aktuellen Standzeiten aller seiner Waggons
                        anzeigen lassen oder über die Suchfunktion die
                        (historischen) Standzeiten nach bestimmten Parametern
                        sortiert anzeigen lassen.
                    </p>
                </li>
                <li>
                    <p>Laufleistungsprognose</p>
                    <p>
                        In dem Feature werden nur solche Waggons des Nutzers
                        angezeigt, für die der Kunde einen noch laufenden VTG
                        Connect Vertrag abgeschlossen hat. Etwaige Störungen bei
                        der Datenübermittlung vom Waggon an das Netz von VTG
                        fallen dabei unter den jeweiligen VTG Connect Vertrag.
                        Das Feature hilft dem Nutzer dabei, die Laufleistung
                        seiner Waggon-Flotte für das laufende Kalenderjahr
                        einzuschätzen. Dabei erstellt VTG für jeden Wagen der
                        Flotte eine Prognose anhand der bisherigen Laufleistung
                        (seit der Anmietung) des konkreten Waggons und weiterer
                        relevanter historischer Daten, die VTG zur Verfügung
                        stehen, um abzuschätzen, wie viele Kilometer der
                        konkrete Waggon voraussichtlich aufgrund der bestehenden
                        Datenlage in dem Kalenderjahr fahren wird. Dabei wird
                        dem Nutzer regelmäßig ein Erwartungskorridor angezeigt,
                        der Unregelmäßigkeiten bei der Prognose aufgrund
                        unsicherer Datenlage abdecken soll. Je länger das
                        Feature aktiviert ist, desto besser ist der Algorithmus
                        von VTG in der Lage die konkrete Laufleistung zu
                        prognostizieren und desto genauer wird die Prognose. Bei
                        der auf der Plattform angezeigten Laufleistungsprognose
                        handelt es sich um eine unverbindliche Prognose, die
                        weitgehend auf den VTG zur Verfügung stehenden
                        historischen Daten beruht, die keine verlässliche
                        Berechnung für zukünftiges Verhalten darstellen können.
                        VTG kann daher keine Gewähr für die Verlässlichkeit und
                        Richtigkeit der angezeigten Ergebnisse im Einzelfall
                        übernehmen. Der Nutzer bleibt in jedem Fall selbst dafür
                        verantwortlich sicherzustellen, dass die vertraglich mit
                        dem Vermieter vereinbarte Laufleistung nicht
                        überschritten wird.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>Zubuchbare traigo-Tools und Features</h4>
            <ol>
                <li>
                    <p>
                        VTG kann nach eigenem Ermessen zusätzliche traigo-Tools
                        oder Features in einem traigo-Tool auf der Plattform
                        anbieten ("<strong>Buchoptionen</strong>"), die der
                        Nutzer einzeln hinzubuchen kann. Gegebenenfalls ist
                        hierfür ein separater Vertragsschluss zwischen VTG und
                        dem Nutzer erforderlich. Die Buchoption können auch
                        Gegenstand von besonderen Nutzungsbedingungen sein, die
                        ergänzend zu diesen Nutzungsbedingungen gelten. Es kann
                        auch möglich sein, dass einige Buchoptionen nur gegen
                        Entgelt nutzbar sind. VTG wird den Nutzer auf die
                        Details der Buchoptionen sowie entsprechende Entgelte
                        und ergänzende Nutzungsbedingungen hinweisen, sobald
                        eine buchbare Option verfügbar ist.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>Nutzung der Plattform</h4>
            <ol>
                <li>
                    <p>
                        Das Zugangs- und Nutzungsrecht beschränkt sich
                        grundsätzlich auf die Plattform in dem bei
                        Vertragsschluss bestehenden Zustand. VTG kann jedoch
                        nach eigenem Ermessen Buchoptionen hinzufügen oder
                        anbieten, die im Falle der Freischaltung durch den
                        Nutzer Teil des Nutzungsvertrages werden, wobei
                        gegebenenfalls ergänzende Nutzungsbedingungen gelten.
                        Der Nutzer ist selbst für den technischen Zugang zu der
                        Plattform (insbesondere hinreichend schnelle
                        Internet-Verbindung) verantwortlich. Um eine
                        bestmögliche Darstellung der Inhalte auf der Plattform
                        zu gewährleisten, ist ein aktueller Webbrowser (z.B.,
                        Chrome oder Safari) erforderlich.
                    </p>
                </li>
                <li>
                    <p>
                        Auf Anfrage und nach freier Entscheidung von VTG erhält
                        der Nutzer Zugangsdaten zu seinem Plattform-Account
                        ("<strong>Account</strong>") per E-Mail. Der Nutzer
                        bestimmt einen Plattform-Administrator, der weiteren
                        Mitarbeitern des Nutzers in angemessener Anzahl durch
                        einen eigenen Account Zugang zur Plattform zur Verfügung
                        stellen kann, sofern er sichergestellt hat, dass diese
                        mit diesen Nutzungsbedingungen vertraut sind und sich zu
                        deren Einhaltung verpflichtet haben. Der Zugang zur
                        Plattform erfordert, dass jede Person, die sich auf der
                        Plattform einloggt, diese Nutzungsbedingungen für sich
                        selbst und den Nutzer akzeptiert.
                    </p>
                </li>
                <li>
                    <p>
                        Der Nutzer ist dazu verpflichtet, die Plattform und die
                        traigo-Tools ausschließlich für die in diesen
                        Nutzungsbedingungen vorgesehenen Zwecke zu nutzen.
                        Insbesondere ist jegliche Nutzung untersagt, die zu
                        einem Schaden an der Plattform, VTG, anderer Nutzer oder
                        Dritter führen kann oder gegen gesetzliche Bestimmungen
                        verstößt. Bei Verstößen gegen diese Nutzungsbedingungen
                        behält VTG sich das Recht vor, den entsprechenden
                        Account zu sperren bis sichergestellt ist, dass keine
                        weiteren Verletzungen begangen werden.
                    </p>
                </li>
                <li>
                    <p>
                        Vorbehaltlich der Verpflichtung in Ziffer 2.3 haftet der
                        Nutzer für einen Verstoß gegen diese
                        Nutzungsbedingungen, die unter seinem Account begangen
                        wurden (gleich ob durch autorisierte Mitarbeiter oder
                        Dritte), wie für eigenes Verschulden.
                    </p>
                </li>
                <li>
                    <p>
                        Ein Account ist nicht auf Dritte übertragbar und alle
                        Rechte an dem Account erlöschen mit der Beendigung des
                        Nutzungsvertrages. Bemerkt der Nutzer oder ein
                        Mitarbeiter des Nutzers den Verlust seiner Account-Daten
                        oder den Zugriff Dritter auf seinen Account auf der
                        Plattform oder auf seine persönlichen Zugangsdaten zum
                        Account auf der Plattform, so ist der Nutzer
                        verpflichtet, dies unverzüglich per E-Mail an
                        <a href="mailto:servicedesk@traigo.com"
                            >servicedesk&#64;traigo.com</a
                        >
                        zu melden.
                    </p>
                </li>
                <li>
                    <p>
                        Die Plattform ist Eigentum von VTG und VTG hält
                        sämtliche Nutzungsrechte an der Plattform. Der Nutzer
                        ist für die Dauer des Nutzungsvertrages auf nicht
                        exklusiver Basis zur Nutzung der Plattform und der
                        jeweils zur Verfügung gestellten traigo-Tools
                        berechtigt, sofern dies im Einklang mit den Bestimmungen
                        dieser Nutzungsbedingungen geschieht. Der Nutzer ist
                        insbesondere nicht dazu berechtigt, die Plattform zu
                        Zwecken zu nutzen, die dazu geeignet sind, VTG zu
                        schädigen oder den Betrieb der Plattform auszunutzen
                        oder zu gefährden.
                    </p>
                </li>
                <li>
                    <p>
                        Die Plattform wird auf den Servern von Amazon Web
                        Services in Deutschland gehostet. Der Nutzer ist daher
                        zusätzlich verpflichtet, sich an die in den
                        Nutzungsbedingungen von Amazon Web Services festgelegten
                        Verhaltenspflichten für die Nutzung zu halten:
                        <a
                            href="https://d1.awsstatic.com/legal/aws-customer-agreement/AWS_Customer_Agreement-DE_(2019-04-30).pdf"
                            >https://d1.awsstatic.com/legal/aws-customer-agreement/AWS_Customer_Agreement-DE_(2019-04-30).pdf</a
                        >. Ferner gelten zusätzlich die dort festgelegten
                        Regelungen zur Erreichbarkeit der AWS-Dienste.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>Service Desk</h4>
            <ol>
                <li>
                    <p>
                        VTG stellt dem Nutzer einen Service Desk für den
                        technischen und fachlichen First Level Support sowie
                        Nutzer-Management im Zusammenhang mit der Plattform zur
                        Verfügung. Der Service Desk ist in deutscher und
                        englischer Sprache verfügbar.
                    </p>
                </li>
                <li>
                    <p>
                        Der Service Desk ist von Montag bis Freitag (ausgenommen
                        gesetzliche Feiertage in Hamburg) jeweils zwischen 09.00
                        und 17.00 Uhr unter
                        <a href="mailto:servicedesk@traigo.com"
                            >servicedesk&#64;traigo.com</a
                        >
                        erreichbar.
                    </p>
                </li>
                <li>
                    <p>
                        Der Service Desk wird sich bemühen, Anfragen
                        unverzüglich zu beantworten und gemeinsam mit dem Nutzer
                        eine Lösung für etwaig bestehende Probleme zu finden. In
                        jedem Fall erhält der Nutzer eine Mitteilung zum
                        Bearbeitungsstand bis spätestens 17.00 Uhr am folgenden
                        Werktag.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>Gewährleistung und Haftung</h4>
            <ol>
                <li>
                    <p>
                        VTG unternimmt alle zumutbaren Anstrengungen, um eine
                        möglichst ununterbrochene Erreichbarkeit der Plattform
                        und Funktionsfähigkeit der traigo-Tools zu
                        gewährleisten. Aufgrund der technischen Struktur des
                        Internet kann VTG aber nicht zusichern, dass die
                        Plattform rund um die Uhr erreichbar ist und dass sie
                        bzw. die traigo-Tools für den Nutzer uneingeschränkt
                        nutzbar ist/sind. VTG bemüht sich, die Einschränkungen
                        der Nutzbarkeit so gering wie möglich zu halten. Von
                        Zeit zu Zeit kann es erforderlich sein, die Plattform
                        aus Wartungsgründen vom Netz zu nehmen, um die
                        langfristige Funktionsfähigkeit sicherzustellen. Sofern
                        steuerbar, wird VTG den Nutzer rechtzeitig über die
                        Wartungszeiträume informieren und nach Möglichkeit dafür
                        sorgen, dass diese außerhalb der üblichen
                        Geschäftszeiten liegen. Im Falle der Anzeige der
                        Nichterreichbarkeit oder von sonstigen Fehlern der
                        Plattform oder einzelner traigo-Tools durch den Nutzer
                        wird VTG sich bemühen, die Störung schnellstmöglich zu
                        beseitigen oder dem Nutzer einen Alternativweg zur
                        Umgehung des Problems zur Verfügung zu stellen. Weitere
                        Ansprüche des Nutzers im Zusammenhang mit der
                        fehlerhaften Zurverfügungstellung der Plattform oder
                        mangelnder Funktionsfähigkeit bestehen nicht
                        (vorbehaltlich etwaiger Schadenersatzansprüche im Falle
                        eines Verschuldens von VTG und der Ansprüche in Ziffer
                        6.3).
                    </p>
                </li>
                <li>
                    <p>
                        Bei inhaltlichen Fehlern auf der Plattform wird VTG
                        diese nach Mitteilung durch den Nutzer umgehend
                        korrigieren und versuchen, das Problem mit dem Nutzer
                        gemeinsam zu lösen.
                    </p>
                </li>
                <li>
                    <p>
                        Sollten Buchoptionen für eine nicht unerhebliche Zeit
                        nicht fehlerfrei nutzbar sein (Wartungszeiten gemäß
                        Ziffer 6.1 ausgenommen), ist das (etwaig) für diese
                        Buchoptionen anfallende Entgelt in angemessenem
                        Verhältnis zu dem Zeitraum, in dem Beeinträchtigungen
                        bestanden sowie unter Berücksichtigung der Schwere der
                        Beeinträchtigungen, zu kürzen.
                    </p>
                </li>
                <li>
                    <p>
                        Die Haftung nach den gesetzlichen Vorschriften (gleich
                        welchen Rechtsgrunds) von VTG und seinen gesetzlichen
                        Vertretern und Erfüllungsgehilfen ist beschränkt auf
                        Schäden, (a) die auf Vorsatz oder grober Fahrlässigkeit
                        von VTG, deren Vertretern oder Erfüllungsgehilfen
                        beruhen, (b) an Leib, Leben oder Gesundheit oder (c) die
                        auf der schuldhaften Verletzung wesentlicher
                        Vertragspflichten beruhen. Im letzteren Fall ist die
                        Haftung jedoch bei einfacher Fahrlässigkeit auf den
                        Schaden beschränkt, der bei Vertragsschluss
                        typischerweise vorhersehbar war.
                    </p>
                    <p>
                        Die Haftung für den Verlust von Daten ist auf den
                        Aufwand beschränkt, der zur Wiederherstellung der Daten
                        bei ordnungsgemäßer und regelmäßiger Datensicherung
                        erforderlich gewesen wäre. Der Nutzer ist für eine
                        entsprechende Datensicherung selbst verantwortlich.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Freistellung</h4>
            <ol>
                <li>
                    <p>
                        Der Nutzer wird VTG von allen Schäden, Ansprüchen
                        Dritter und sonstigen Kosten (einschließlich
                        angemessener Rechtsanwaltskosten) freistellen, die VTG
                        daraus entstehen, dass der Nutzer seine Pflichten aus
                        diesen Nutzungsbedingungen verletzt.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Vertraulichkeit</h4>
            <ol>
                <li>
                    <p>
                        Der Nutzer wird sämtliche Informationen, die er im
                        Zusammenhang mit der Plattform, den traigo-Tools oder
                        diesen Nutzungsbedingungen erhält ("<strong
                            >Vertrauliche Informationen</strong
                        >") streng vertraulich behandeln und nicht für andere
                        Zwecke nutzen außer die Erfüllung der vertraglichen
                        Rechte und Pflichten unter diesen Nutzungsbedingungen.
                    </p>
                </li>
                <li>
                    <p>
                        Die Verschwiegenheitspflicht unter Ziffer 8.1 gilt nicht
                        für Informationen, von denen der Nutzer nachweisen kann,
                        dass
                    </p>
                    <ul>
                        <li>
                            <p>
                                sie ihm bereits bekannt waren bevor er Zugang zu
                                den Informationen von VTG erhalten hat;
                            </p>
                        </li>
                        <li>
                            <p>
                                sie ohne Verstoß gegen eine
                                Vertraulichkeitsverpflichtung und ohne
                                Verschulden oder Beteiligung des Nutzers
                                öffentlich bekannt wurden nachdem der Nutzer
                                Zugang zu den Informationen von VTG erhalten
                                hat; oder
                            </p>
                        </li>
                        <li>
                            <p>
                                sie von dem Nutzer unabhängig von der Kenntnis
                                der von VTG zugänglich gemachten Informationen
                                entwickelt wurden.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Der Nutzer ist insoweit nicht hinsichtlich der
                        Geheimhaltung der Vertraulichen Informationen
                        beschränkt, sofern und soweit er hierzu unter
                        anwendbarem Recht verpflichtet ist. Er muss VTG aber
                        sofort darüber informieren, sobald er Kenntnis von der
                        Verpflichtung erlangt (sofern dies nicht gesetzlich
                        untersagt ist).
                    </p>
                </li>
                <li>
                    <p>
                        Die in dieser Ziffer 8 geregelten
                        Vertraulichkeitspflichten bleiben für drei Jahre nach
                        dem Ende der Vertragslaufzeit in Kraft.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Datenschutz</h4>
            <ol>
                <li>
                    <p>
                        VTG verarbeitet im Rahmen der Bereitstellung der
                        Plattform personenbezogene Daten der Nutzer bzw. deren
                        Mitarbeiter. Details zu den Arten und Zwecken der
                        verarbeiteten Daten, mit wem diese geteilt werden, wann
                        sie gelöscht werden und welche Rechte der Betroffenen
                        bestehen, finden Sie in der Datenschutzerklärung für die
                        Plattform. Der Nutzer verpflichtet sich, seinen
                        Mitarbeitern, deren Daten von VTG entsprechend der
                        Datenschutzerklärung verarbeitet werden, diese
                        Datenschutzerklärung rechtzeitig zugänglich zu machen.
                    </p>
                </li>
                <li>
                    <p>
                        Neben den personenbezogenen Daten kann VTG die Nutzung
                        der Plattform in anonymisierter bzw. aggregierter Form
                        auswerten und für seine Geschäftszwecke nutzen.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Laufzeit</h4>
            <ol>
                <li>
                    <p>
                        Der Vertrag zur Nutzung der Plattform kommt mit
                        Bestätigung dieser Nutzungsbedingungen durch den Nutzer
                        (spätestens bei der ersten Anmeldung auf der Plattform)
                        zustande. Der Nutzer stellt sicher, dass lediglich
                        solche Personen Zugang zur Plattform erhalten, die
                        berechtigt sind, diese Vereinbarung für den Nutzer
                        abzuschließen. Der Vertrag läuft vorbehaltlich einer
                        vorzeitigen Kündigung durch eine der Parteien für einen
                        Zeitraum von einem Jahr und verlängert sich automatisch
                        um weitere Einjahreszeiträume, sofern er nicht binnen 15
                        Tage vor Ablauf des jeweils laufenden Jahreszeitraums
                        gekündigt wird.
                    </p>
                </li>
                <li>
                    <p>
                        Das Recht zur außerordentlichen Kündigung bleibt
                        unberührt. Ein Recht zur außerordentlichen Kündigung
                        besteht für VTG insbesondere dann, wenn der Nutzer der
                        Plattform vorsätzlich Schaden zufügt, gegen die
                        Bestimmungen dieser Nutzungsbedingungen verstößt oder
                        die Plattform für andere als die in diesen
                        Nutzungsbedingungen erlaubten Zwecke nutzt.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Sonstiges</h4>
            <ol>
                <li>
                    <p>
                        Jede Änderung oder Ergänzung dieser Nutzungsbedingungen
                        bedarf der Schriftform. Dies gilt auch für eine Änderung
                        oder den Verzicht auf dieses Schriftformerfordernis.
                    </p>
                </li>
                <li>
                    <p>
                        Auf diese Nutzungsbedingungen und deren Auslegung ist
                        ausschließlich deutsches Recht unter Ausschluss seiner
                        kollisionsrechtlichen Bestimmungen und des UN-Kaufrechts
                        anzuwenden. Ausschließlicher Gerichtsstand für jegliche
                        Streitigkeiten im Zusammenhang mit der Plattform oder
                        diesen Nutzungsbedingungen ist Hamburg.
                    </p>
                </li>
                <li>
                    <p>
                        Sollten Bestimmungen dieser Nutzungsbedingungen ganz
                        oder teilweise unwirksam sein oder werden oder sollte
                        sich eine ergänzungsbedürftige Lücke herausstellen, so
                        wird dadurch die Gültigkeit der übrigen Bestimmungen
                        nicht berührt. Vielmehr verpflichten sich die Parteien,
                        die unwirksame oder undurchführbare Bestimmung durch
                        eine andere, im wirtschaftlichen Erfolg ihr nach
                        Möglichkeit gleichkommende, wirksame Bestimmung zu
                        ersetzen.
                    </p>
                </li>
                <li>
                    <p>
                        Diese Nutzungsbedingungen wurden zweisprachig erstellt.
                        Im Falle von Widersprüchen zwischen der deutschen und
                        der englischen Fassung genießt die deutsche Fassung
                        Vorrang.
                    </p>
                </li>
            </ol>
        </li>
    </ol>
</section>
