<app-page>
    <ng-container [ngSwitch]="language$ | async">
        <app-terms-conditions-content-de
            *ngSwitchCase="Language.DE"
        ></app-terms-conditions-content-de>
        <app-terms-conditions-content-en
            *ngSwitchDefault
        ></app-terms-conditions-content-en>
    </ng-container>

    <div class="ml-auto text-primary flex justify-end pt-8">
        <app-back-to-top></app-back-to-top>
    </div>
</app-page>
