<app-notification-bell
    cdkOverlayOrigin
    [active]="overlayIsOpen"
    (click)="toggleOverlay()"
    trackClick
    [trackCategory]="AnalyticsCategory.Notification"
    [trackAction]="AnalyticsNotificationAction.ClickBell"
></app-notification-bell>

<ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayGrowAfterOpen
    [cdkConnectedOverlayOpen]="overlayIsOpen"
    [cdkConnectedOverlayOrigin]="overlayOrigin"
    [cdkConnectedOverlayPositions]="overlayPositions"
>
    <div *ngIf="overlayIsOpen" @overlayTrigger class="notification-overly">
        <div
            #overlayHeader
            class="notification-overly__header"
            [class.is-loading]="loading$ | async"
        >
            <a
                routerLink="/notifications"
                class="no-underline text-accent-extra-dark"
                trackClick
                [trackCategory]="AnalyticsCategory.Notification"
                [trackAction]="AnalyticsNotificationAction.PanelClickHeadline"
            >
                <h3 class="notification-overly__title" translate>
                    Notifications
                </h3>
            </a>
            <app-button
                iconButton
                icon="settings"
                routerLink="/account/notification-settings"
                [matTooltip]="'Notification Settings' | translate"
                aria-label="Notification Settings"
                trackClick
                [trackCategory]="AnalyticsCategory.Notification"
                [trackAction]="AnalyticsNotificationAction.ClickCockpitLink"
                [trackLabel]="'Notification Panel'"
            >
            </app-button>
        </div>

        <div
            class="notification-overlay__content scrollbar"
            appIntersectionContext
            intersectionRootMargin="0px"
            [intersectionThreshold]="1"
        >
            <app-system-response
                *ngIf="error$ | async; else notifications"
                responseType="error"
                title="NotificationLoadingErrorTitle"
                message="NotificationLoadingErrorMessage"
                small
            />
            <ng-template #notifications>
                <app-notification-list
                    [notifications]="notifications$ | async"
                    [showSkeletons]="showSkeletons$ | async"
                >
                    <a
                        *ngIf="(notifications$ | async)?.length"
                        footer
                        routerLink="/notifications"
                        class="notification-overlay__show-all"
                        trackClick
                        [trackCategory]="AnalyticsCategory.Notification"
                        [trackAction]="
                            AnalyticsNotificationAction.PanelClickShowAll
                        "
                    >
                        <translate>Show all</translate>
                    </a>
                </app-notification-list>
            </ng-template>
        </div>
    </div>
</ng-template>
