/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { isDevelopment } from '@env-lib/isDevelopment'
import { isTestCafeTest } from '@env-lib/isTestCafeTest'
import { AppModule } from '@portal/app.module'
import dayjs from 'dayjs'

if (isTestCafeTest() || !isDevelopment()) {
    enableProdMode()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err))

// Add global to window, assigning the value of window itself.
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
;(window as any).global = window
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
;(window as any).dayjs = dayjs
