import { AsyncPipe } from '@angular/common'
import {
    ChangeDetectorRef,
    OnDestroy,
    Pipe,
    PipeTransform,
} from '@angular/core'
import { TranslationParameters } from '@localization-lib/language/models/TranslationParameters'
import { TranslationService } from '@localization-lib/language/translation.service'
import { Observable } from 'rxjs'

@Pipe({
    name: 'translate',
    pure: false, // eslint-disable-line @angular-eslint/no-pipe-impure
})
export class TranslationPipe implements PipeTransform, OnDestroy {
    private lastValue?: string
    private lastParams?: TranslationParameters
    private observable?: Observable<string>
    private asyncPipe: AsyncPipe

    constructor(
        private translate: TranslationService,
        private _ref: ChangeDetectorRef
    ) {
        this.asyncPipe = new AsyncPipe(this._ref)
    }

    ngOnDestroy() {
        // eslint-disable-next-line @angular-eslint/no-lifecycle-call
        this.asyncPipe.ngOnDestroy()
    }

    transform(value: string, params?: TranslationParameters) {
        if (
            this.lastValue !== value ||
            this.lastParams !== params ||
            !this.observable
        ) {
            this.observable = this.translate.getTranslation$(value, params)
        }
        this.lastValue = value
        this.lastParams = params
        return this.asyncPipe.transform(this.observable)
    }
}
