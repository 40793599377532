<header>
    <h4>Terms of Use for the Platform traigo</h4>
    <p class="subtitle">(Version 2.1 as of 26.11.2024)</p>
    <p>
        These Terms of Use apply to the access to and use of the internet
        platform traigo ("<strong>Platform</strong>") provided by VTG Rail
        Europe GmbH ("<strong>VTG</strong>") to certain selected commercial
        customers (each a "<strong>User</strong>", VTG or the User each also a
        "<strong>Party</strong>" or collectively "<strong>Parties</strong>").
        The use of the Platform is exclusively governed by these Terms of Use
        and the accompanying documents expressly referenced in these Terms of
        use.
    </p>
</header>

<section>
    <ol>
        <li class="li-h4">
            <h4>Subject</h4>
            <ol>
                <li>
                    <p class="li-p">
                        Subject of these Terms of Use is granting access and
                        usage rights to the Platform for the purpose of enabling
                        the User to use its currently available traigo tools.
                        The traigo tools are provided via the platform.
                        Additionally, there is the option for ITSS data transfer
                        of position and geofence data into the user's system.
                        The configuration can be carried out by the respective
                        customer administrator on the traigo platform.
                    </p>
                </li>
                <li>
                    <p>The Platform provides for the following traigo tools:</p>
                    <ul>
                        <li><p>Contract Management</p></li>
                        <li><p>Fleet Management</p></li>
                        <li><p>Wagon Tracking</p></li>
                        <li><p>ETA Calculation</p></li>
                        <li><p>Idle Times</p></li>
                        <li><p>Mileage Prediction</p></li>
                    </ul>
                    <p>
                        (along with any other tools VTG may decide in its sole
                        discretion to offer, potentially governed by additional
                        terms of use and against remuneration, the "<strong
                            >traigo tools</strong
                        >". The traigo tools are described in detail below).
                    </p>
                    <p>
                        The traigo tools are available for wagons leased by the
                        user from VTG. Additionally, the traigo tools are
                        available for third-party wagons under a VTG Connect
                        service contract that the user has leased from the third
                        party. Furthermore, the traigo tools areavailable for
                        third-party wagons equipped with third-party connectors
                        that the user has leased from the third party, provided
                        that the user ensures the third parties supply VTG with
                        the necessary data via ITSS for usage.The Wagon Tracking
                        traigo tool is only available to the extent the User has
                        entered into a respective VTG Connect agreement. The
                        access to the Platform as well as the use of the traigo
                        tools listed in Clause 2 are free of charge (unless a
                        separate cost-bearing contract is required for certain
                        traigo tools, such as a VTG Connect contract, see below
                        Clause 2.3 and 2.4). For additional traigo tools
                        additional fees may incur (see below Clause 3).
                    </p>
                </li>
                <li>
                    <p>
                        VTG is at any time entitled to change the Platform, the
                        traigo tools or these Terms of Use including the
                        complete cease of offering the Platform.
                    </p>
                </li>
                <li>
                    <p>
                        In the event of material changes to the Platform or the
                        traigo tools (i.e. such changes which materially amend
                        or limit the existing traigo tools to the detriment of
                        the User) or to these Terms of Use VTG will inform the
                        User upon a 15 days' notice period. Potential changes
                        will become binding for the User if he does not reject
                        in writing within this deadline or continues to use the
                        Platform after expiration of the deadline. VTG will
                        inform the User about this effect along with the change
                        notice. Should the User reject the changes VTG has an
                        extraordinarily termination right in case it cannot
                        provide the Platform or traigo tools without the changes
                        with reasonable efforts.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>traigo tools</h4>
            <ol>
                <li>
                    <p>Contract Management</p>
                    <p>
                        The Contract Management Tool enables the User to view
                        the contract details for the wagon lease agreements with
                        VTG. The contract details include in particular the
                        respective contract term, included wagons and lease fee
                        per wagon. This information is displayed solely on the
                        basis of the data available to VTG. The displayed
                        information is solely if informational nature and has no
                        whatsoever effect on the contract itself. The User is
                        thus in any case obliged to validate the correctness of
                        the information and inform VTG about any potential
                        errors. Any potential declarations or messages submitted
                        by the User via the Platform to VTG have no legal effect
                        on the lease agreements. Termination notices, contract
                        renewals or similar declarations must always be
                        submitted to the correct point of contact at VTG or the
                        respective correct VTG entity.
                    </p>
                </li>
                <li>
                    <p>Fleet Management</p>
                    <p>
                        The Fleet Management Tool enables the User to view
                        various information related to the fleet of wagons
                        leased by the user. This information includes in
                        particular an overview of the complete fleet of leased
                        wagons, the status of single wagons (e.g.
                        revisionstatus) as well as display of the relevant
                        capacities. In addition, the User can filter the
                        complete fleet leased by him for certain criteria (e.g.
                        type of wagon, agreed transport goods). This information
                        is displayed solely on the basis of the data available
                        to VTG. The displayed information is solely if
                        informational nature and has no whatsoever effect on the
                        contract itself. The User is thus in any case obliged to
                        validate the correctness of the information and inform
                        VTG about any potential errors.
                    </p>
                </li>
                <li>
                    <p>Wagon Tracking</p>
                    <p>
                        To the extent the User has entered into a VTG Connect
                        contract for single or all of the leased wagons, he can
                        track the leased wagons in (near) real time via the
                        Wagon Tracking Tool. The current location of a wagon can
                        be filtered. The Platform can only display information
                        transmitted by the relevant VTG Connector. Potential
                        malfunctions concerning the data transmission from the
                        wagon to the network of VTG are subject to the relevant
                        VTG Connect contract.
                    </p>
                </li>
                <li>
                    <p>ETA Calculation</p>
                    <p>
                        This Tool allows for a travel time estimation for a
                        wagon to a specific destination. For this purpose, the
                        existing motion data of other wagons which are equipped
                        with a VTG Connector will be analyzed on anonymous basis
                        for providing the estimated travel time with three
                        different results (fastest, slowest and average travel
                        time). These results are solely based on the tracked
                        travel times of wagons in the past and on the relevant
                        month, day and time of the journey and other
                        characteristics. Thus, this data are empirical values
                        based on the available data sets. VTG can thus not
                        warrant the correctness and reliability of the displayed
                        results in a single case.
                    </p>
                </li>
                <li>
                    <p>Idle Times</p>
                    <p>
                        The tool helps the user calculate the respective idle
                        times for each individual wagon in their fleet, enabling
                        an assessment of the overall utilization of the wagon
                        fleet. In the tool, only those wagons for which the
                        customer has an active VTG Connect contract are
                        displayed. Wagons whose VTG Connector has not
                        transmitted data for at least 72 consecutive hours are
                        shown as inactive. Any disruptions in data transmission
                        from the wagon to the VTG network are covered under the
                        respective VTG Connect contract. A wagon is considered
                        stationary if it moves less than 10 km within a sixhour
                        period. Consequently, to determine whether a wagon is
                        stationary, the calculation of idle times may involve a
                        delay of at least six hours. Through the tool, the
                        customer can view the current idle times for all their
                        wagons or, by using the search function, retrieve
                        (historical) idle times based on specific criteria.
                    </p>
                </li>
                <li>
                    <p>Mileage Prediction</p>
                    <p>
                        This feature displays only those wagons for which the
                        user has an active VTG Connect contract. Any disruptions
                        in data transmission from the wagon to the VTG network
                        are covered under the respective VTG Connect contract.
                        The feature assists the user in estimating the mileage
                        of their wagon fleet for the current calendar year. VTG
                        generates a forecast for each wagon in the fleet based
                        on its mileage to date (since the start of the lease)
                        and other relevant historical data available to VTG, to
                        estimate how many kilometers the wagon is expected to
                        travel within the calendar year based on existing data.
                        The user is regularly shown an expected range that
                        accounts for forecast uncertainties due to an uncertain
                        data foundation. The longer the feature is active, the
                        better VTG’s algorithm can predict the specific mileage,
                        improving the forecast's accuracy. The mileage forecast
                        shown on the platform is a non-binding estimate
                        primarily based on historical data available to VTG,
                        which may not reliably predict future performance.
                        Consequently, VTG cannot guarantee the reliability and
                        accuracy of the displayed results in individual cases.
                        The user remains solely responsible for ensuring that
                        the mileage agreed upon in the lease contract with the
                        lessor is not exceeded.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>OPTIONAL tools and features FOR BOOKING</h4>
            <ol>
                <li>
                    <p>
                        VTG may in its own discretion offer additional traigo
                        tools or features to a traigo tool on the Platform
                        ("<strong>Booking Options</strong>"), which can be
                        purchased by the User on single basis. This may require
                        a separate contract conclusion between VTG and the User.
                        The Booking Options may also be subject to additional
                        terms of use which apply in addition to these Terms of
                        Use. Certain Booking Options may only be available
                        against remuneration. VTG will inform the User about the
                        details of the Booking Options as well as respective
                        fees and additional terms of use as soon as such Booking
                        Option is available.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>Use of the Platform</h4>
            <ol>
                <li>
                    <p>
                        The access and usage right is generally limited to the
                        Platform in its current state at contract conclusion.
                        However, VTG may in its own discretion offer or add
                        Booking Options which will be upon activation by user
                        become part of the use agreement potentially amended by
                        additional terms of use The User is solely responsible
                        for the technical connection to the Platform (in
                        particular sufficiently scaled internet connection). In
                        order to ensure the best possible experience of the
                        Platform a state of the art web browser is required
                        (e.g. Chrome or Safari).
                    </p>
                </li>
                <li>
                    <p>
                        After request and free decision of VTG the User will
                        receive access data to his platform account
                        ("<strong>Account</strong>") via email. The User
                        appoints a Platform Administrator who is entitled to
                        grant further employees of the User in reasonable scale
                        access to the Platform via own Accounts if he has
                        ensured that these are familiar with these Terms of Use
                        and have obliged themselves to comply with them. Access
                        to the Platform requires that each individual logging in
                        on the Platform accepts these Terms of Use for himself
                        and on behalf of the User.
                    </p>
                </li>
                <li>
                    <p>
                        The User is obliged to use the Platform and the traigo
                        tools solely for the purpose defined in these Terms of
                        Use. In particular any kind of use is prohibited that
                        may lead to a damage of the Platform, VTG, other Users
                        or third parties or violates statutory legal provisions.
                        In the event of violations of the provisions of these
                        Terms of Use VTG retains the right to block the
                        respective Account until it is ensured that no further
                        violations will be committed.
                    </p>
                </li>
                <li>
                    <p>
                        Subject to the provision in Clause 2.3 the User is
                        liable for any breach of these Terms of Use under his
                        Account (regardless of whether by authorized employees
                        or third parties) in the same scope as for own breach.
                    </p>
                </li>
                <li>
                    <p>
                        The Account is not transferrable to third parties and
                        all rights in and to the Account terminate upon the
                        expiration of the usage agreement. If the User or one of
                        his employees becomes aware of the loss of his Account
                        data or access to his Account or personal access data to
                        the Account he must notify this without undue delay by
                        email to:
                        <a href="mailto:servicedesk@traigo.com"
                            >servicedesk&#64;traigo.com</a
                        >
                    </p>
                </li>
                <li>
                    <p>
                        VTG owns all right, title and interest in and to the
                        Platform and all respective usage rights to the
                        Platform. The User is entitled during the term of the
                        usage contract on non-exclusive basis to use the
                        Platform and the provided traigo tools in compliance
                        with these Terms of Use. The User is in particular not
                        entitled to use the Platform for purposes which are
                        suitable to harm VTG or to take advantage of or threaten
                        the operation of the Platform.
                    </p>
                </li>
                <li>
                    <p>
                        The Platform is hosted on the servers of Amazon Web
                        Services in Germany. Additionally, the User is obliged
                        to comply with the rules of conduct laid down in the
                        terms of use of Amazon Web Services:
                        <a
                            href="https://d1.awsstatic.com/legal/aws-customer-agreement/AWS_Customer_Agreement-DE_(2019-04-30).pdf"
                            >https://d1.awsstatic.com/legal/aws-customer-agreement/AWS_Customer_Agreement-DE_(2019-04-30).pdf</a
                        >. The provisions on accessibility to the AWS services
                        in these terms of use apply as well.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>Service Desk</h4>
            <ol>
                <li>
                    <p>
                        VTG offers a service desk for technical and
                        content-related first level support and user management
                        for the Platform to the User. The service desk is
                        available in German and English language.
                    </p>
                </li>
                <li>
                    <p>
                        The service desk is available from Monday to Friday
                        (excluding national holidays in Hamburg) from 09.00am to
                        05.00pm at
                        <a href="mailto:servicedesk@traigo.com"
                            >servicedesk&#64;traigo.com</a
                        >.
                    </p>
                </li>
                <li>
                    <p>
                        The service desk will seek to respond without undue
                        delay to requests and to find a solution with the User
                        for potential issues. In any case, the User will receive
                        a written status update of the request until 05.00pm on
                        the following work day.
                    </p>
                </li>
            </ol>
        </li>
        <li class="li-h4">
            <h4>Warranty and Liability</h4>
            <ol>
                <li>
                    <p>
                        VTG applies all reasonable efforts to ensure an
                        uninterrupted access to the Platform and functionality
                        of the traigo tools. However, due to the technical
                        structure of the internet, VTG cannot warrant that the
                        Platform is accessible 24/7 and that all traigo tools
                        can be used without restrictions. VTG undertakes
                        reasonable efforts to minimize use restrictions as much
                        as possible. From time to time it may be required to
                        take the Platform offline for maintenance reasons and to
                        ensure longtime functionality. To the extent
                        predictable, VTG will inform the User about upcoming
                        maintenance periods and will ensure (if possibe) that
                        the maintenance periods are outside the usual business
                        times. In case of a notice for the Platform not being
                        available or other malfunctions of the Platform or
                        single traigo tools by the User, VTG will endeavor to
                        remedy the defect as soon as possible or to present a
                        work around to the User. The User is not entitled to any
                        other claims relating to the defective provision of the
                        Platform or malfunctions (excluding potential damage
                        claims in case of fault of VTG and claims under Clause
                        6.3).
                    </p>
                </li>
                <li>
                    <p>
                        In the event of any content-related errors on the
                        Platform VTG will correct these immediately after notice
                        by user and try to solve the issue directly with the
                        respective User.
                    </p>
                </li>
                <li>
                    <p>
                        Should the Booking Options not be available or usable
                        for a material period (excluding maintenance periods
                        under Clause 6.1), the fees for such Booking Options (if
                        any) shall be reduced in adequate scale taking into
                        account the length as well as the severity of the
                        interruption or error.
                    </p>
                </li>
                <li>
                    <p>
                        The liability under statutory law (regardless of the
                        legal basis) of VTG and its legal representatives and
                        vicarious agents is limited to damages (a) resulting
                        from intent and gross negligence of VTG, its legal
                        representatives or vicarious agents, (b) to life, body
                        or health or (c) resulting from the culpable violation
                        of material contractual obligations. In the latter case
                        the liability shall be limited in case of slight
                        negligence to the damage which was typically foreseeable
                        at the time of contract conclusion.
                    </p>
                    <p>
                        The liability for the loss of data is limited to the
                        effort for retrieval that would have occurred in case of
                        regular and proper back-ups. The User is solely
                        responsible for conducting the back-ups.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Indemnity</h4>
            <ol>
                <li>
                    <p>
                        The User shall indemnify and hold harmless VTG from any
                        damages, claims of third parties or other costs
                        (including reasonable attorney's fees) which incur to
                        VTG due to the User's breach of its obligations under
                        these Terms of Use.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Confidentiality</h4>
            <ol>
                <li>
                    <p>
                        The User undertakes to treat confidentially any and all
                        information he may obtain with regards to the Platform,
                        the traigo tools or these Terms of Use ("<strong
                            >Confidential Information</strong
                        >"), and to refrain from using them for any purpose and
                        to any extent other than performance of these Terms of
                        Use.
                    </p>
                </li>
                <li>
                    <p>
                        The duty to maintain confidentiality under Clause 8.1
                        does not apply to information which can be evidenced by
                        the User to:
                    </p>
                    <ul>
                        <li>
                            <p>
                                have already been known to the User before he
                                was granted access to such information by VTG;
                            </p>
                        </li>
                        <li>
                            <p>
                                have become publicly known after such
                                information has been made accessible by VTG
                                without breach of a confidentiality obligation
                                and without participation or culpability on the
                                part of the User; or
                            </p>
                        </li>
                        <li>
                            <p>
                                have been generated by the User independently
                                from knowledge of the information made
                                accessible by VTG.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        The User shall not be restricted to disclose the
                        Confidential Information to the extent required by
                        applicable law but shall inform VTG immediately of such
                        disclosure after learning of the obligation (unless
                        prohibited by applicable law).
                    </p>
                </li>
                <li>
                    <p>
                        The confidentiality obligations in this Clause 8 will
                        continue to apply over a period of three years after the
                        expiration of the contract term or termination.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Data Protection</h4>
            <ol>
                <li>
                    <p>
                        In the context of providing the Platform VTG processes
                        personal data of the Users and their respective
                        employees. Details regarding the types of data, purposes
                        of processing, sharing of data, data retention /
                        deletion and data subjects' rights are available in the
                        Privacy Policy for the Platform. The User is obliged, to
                        provide this Privacy Policy to its employees in due time
                        if VTG processes their personal data according to the
                        Privacy Policy.
                    </p>
                </li>
                <li>
                    <p>
                        In addition to personal data VTG may analyze the usage
                        of the Platform in anonymized or aggregated form in
                        order to use this knowledge for its business purposes.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Term</h4>
            <ol>
                <li>
                    <p>
                        The agreement for the test use of the Platform becomes
                        binding upon confirmation by the User of these Terms of
                        Use (at the latest upon the first registration on the
                        Platform). The User ensures to grant only access to the
                        Platform to individuals who are duly authorized to enter
                        into the respective agreement on behalf of the User. The
                        agreement remains in force for a period of one year and
                        automatically renews for subsequent one year terms,
                        unless terminated upon 15 days' notice period prior to
                        the expiration of the then current annual term.
                    </p>
                </li>
                <li>
                    <p>
                        The right to termination for cause remains unaffected.
                        VTG shall in particular be entitled to termination for
                        cause if the User intentionally damages the Platform,
                        violates the provision of these Terms of Use or uses the
                        Platform for other purposes than those permitted under
                        these Terms of Use.
                    </p>
                </li>
            </ol>
        </li>

        <li class="li-h4">
            <h4>Miscellaneous</h4>
            <ol>
                <li>
                    <p>
                        Any change or amendment of these Terms of use requires
                        written form. This shall also apply to the change or
                        waiver of this written form agreement.
                    </p>
                </li>
                <li>
                    <p>
                        These Terms of Use shall exclusively be governed by and
                        construed under the laws of Germany excluding its
                        provisions on the conflict of laws and CISG. Exclusive
                        venue for any disputes arising in connection with the
                        Platform or these Terms of Use is Hamburg.
                    </p>
                </li>
                <li>
                    <p>
                        Should any provision of these Terms of Use be or become
                        invalid in whole or in parts or should a loophole
                        requiring closing show up, the validity of the other
                        provisions shall not be affected. Rather, the Parties
                        shall be obliged to replace the invalid or unenforceable
                        provisions with another valid provision as close to the
                        economic effect as possible.
                    </p>
                </li>
                <li>
                    <p>
                        These Terms of Use have been drafted bilingual. In case
                        of inconsistencies between both versions, the German
                        version shall take precedence.
                    </p>
                </li>
            </ol>
        </li>
    </ol>
</section>
