import dayjs from '@localization-lib/date-time/dayjs'
import { DEFAULT_TIMEZONE } from '@localization-lib/date-time/pipes/date-in-default-timezone/date-in-default-timezone.pipe'
import { DEFAULT_LANGUAGE } from '@localization-lib/language/models/Language'
import { isISOString } from '@util-lib/isISOString'
import { Dayjs } from 'dayjs'

/**
 * returns a dayjs object but sets the timezone already to DEFAULT_TIMEZONE
 */
export const defaultDate = (
    dateTime?: string | number | dayjs.Dayjs | Date | null | undefined
) => dayjs(dateTime).tz(DEFAULT_TIMEZONE)

export const dayjsHelper = {
    /**
    * get now in DEFAULT_TIMEZONE
     
    * @returns {Dayjs} dayjs object in DEFAULT_TIMEZONE
   
    */
    nowInDefaultTimezone: () => defaultDate(),
    /**
    * cast ISO string to dayjs, system timezone
     
    * @param {string} isoFormattedString - date with time UTC 0 in ISO 8601 format YYYY-MM-DDTHH:mm:ssZ
     
    * @returns {Dayjs} dayjs object in system timezone
   
    * @example
  
    * stringToDayjs('2019-11-10T23:00:00Z'):
    * 11.11.2019 00:00
    
    * @code dayjs(isoFormattedString)
    */
    stringToDayjs: (isoFormattedString: string) => {
        checkIsoFormattedString(isoFormattedString)

        return dayjs(isoFormattedString)
    },

    /**
    * cast ISO string to dayjs in certain timezone
     
    * @param {string} isoFormattedString - date with time UTC 0 in ISO 8601 format YYYY-MM-DDTHH:mm:ssZ

    * @param {string} timezone - timezone from IANA database as string
     
    * @returns {Dayjs} dayjs object in requested timezone
   
    * @example
  
    * stringToDayjsTZ('2019-11-10T23:00:00Z','America/New_York'):
    * 10.11.2019 18:00
    * $offset: -300
    * $x: {$timezone: 'America/New_York'}
    */
    stringToDayjsTZ: (isoFormattedString: string, timezone: string) =>
        dayjsHelper.stringToDayjs(isoFormattedString).tz(timezone, false),
    /**
    * cast ISO string to dayjs in DEFAULT_TIMEZONE
    
    * @param {string} isoFormattedString - date with time UTC 0 in ISO 8601 format YYYY-MM-DDTHH:mm:ssZ

    * @returns {Dayjs} dayjs object in requested timezone
   
    * @example
  
    * stringToDayjsDefaultTZ('2019-11-10T23:00:00Z'):
    * 11.11.2019 00:00
    * $offset: 60
    * $x: {$timezone: 'Europe/Berlin'}
    * 
    * dayjs(isoFormattedString).tz(DEFAULT_TIMEZONE, false)
    */
    stringToDayjsDefaultTZ: (isoFormattedString: string) =>
        dayjsHelper.stringToDayjsTZ(isoFormattedString, DEFAULT_TIMEZONE),
    /**
    * cast ISO string to dayjs in requested timezone but keep local time 
    * override! use carefully!
     
    * @param {string} isoFormattedString - date with time UTC 0 in ISO 8601 format YYYY-MM-DDTHH:mm:ssZ
   
    * @param {string} timezone - timezone from IANA database as string

    * @returns {Dayjs} dayjs object in requested timezone
   
    * @example
  
    * stringToDayjsTZKeepTime('2019-11-10T23:00:00Z','America/New_York'):
    * 11.11.2019 00:00 (time from local system!!!!)
    * $offset: -300
    * $x: {$timezone: 'America/New_York'}
    */
    stringToDayjsTZKeepTime: (isoFormattedString: string, timezone: string) =>
        dayjsHelper.stringToDayjs(isoFormattedString).tz(timezone, true),

    /**
    * ISO string to formatted string, system timezone
     
    * @param {string} isoFormattedString - date with time UTC 0 in ISO 8601 format YYYY-MM-DDTHH:mm:ssZ
   
    * @param {string} locale - locale abbreviation e.g. 'de' or 'en', default: 'en'
  
    * @param {string} format - format pattern 
     
    * @returns {string} formatted string, default: 'DD.MM.YYYY HH:mm'
   
    * @example
  
    * formatString('2019-11-10T23:00:00Z'):
    * 11.11.2019 00:00 (local system: Europe/Berlin)
    
    * @see https://day.js.org/docs/en/parse/string-format
    */
    formatString: (
        isoFormattedString: string,
        locale?: string,
        format?: string
    ) => {
        checkIsoFormattedString(isoFormattedString)

        return dayjs(isoFormattedString)
            .locale(locale || DEFAULT_LANGUAGE)
            .format(format || 'DD.MM.YYYY HH:mm')
    },

    /**
    * ISO string to formatted string in requested timezone
     
    * @param {string} isoFormattedString - date with time UTC 0 in ISO 8601 format YYYY-MM-DDTHH:mm:ssZ
   
    * @param {string} timezone - timezone from IANA database as string
   
    * @param {string} locale - locale abbreviation e.g. 'de' or 'en', default: 'en'
  
    * @param {string} format - format pattern 
     
    * @returns {string} formatted string, default: 'DD.MM.YYYY HH:mm'
   
    * @example America/New_York UTC -5
    * formatStringInTZ('2019-11-10T23:00:00Z','America/New_York''):
    * 10.11.2019 18:00
    * 
    * @see https://day.js.org/docs/en/parse/string-format
    */
    formatStringInTZ: (
        isoFormattedString: string,
        timezone: string,
        locale?: string,
        format?: string
    ) => {
        checkIsoFormattedString(isoFormattedString)

        return dayjs(isoFormattedString)
            .tz(timezone, false)
            .locale(locale || DEFAULT_LANGUAGE)
            .format(format || 'DD.MM.YYYY HH:mm')
    },

    /**
    * ISO string to formatted string in DEFAULT_TIMEZONE
     
    * @param {string} isoFormattedString - date with time UTC 0 in ISO 8601 format YYYY-MM-DDTHH:mm:ssZ
   
    * @param {string} locale - locale abbreviation e.g. 'de' or 'en', default: 'en'
  
    * @param {string} format - format pattern 
     
    * @returns {string} formatted string, default: 'DD.MM.YYYY HH:mm'
   
    * @example 
    * formatStringInDefaultTZ('2019-11-10T23:00:00Z'):
    * 11.11.2019 00:00
    
    * dayjs(isoFormattedString)
            .tz(DEFAULT_TIMEZONE, false)
            .locale(locale || DEFAULT_LANGUAGE)
            .format(format || 'DD.MM.YYYY HH:mm')
    
    * @see https://day.js.org/docs/en/parse/string-format
    */
    formatStringInDefaultTZ: (
        isoFormattedString: string,
        format?: string,
        locale?: string
    ) =>
        dayjsHelper.formatStringInTZ(
            isoFormattedString,
            DEFAULT_TIMEZONE,
            locale,
            format
        ),
    /**
    * ISO string to formatted string in requested timezone but keep local time 
    * override! use carefully!
     
    * @param {string} isoFormattedString - date with time UTC 0 in ISO 8601 format YYYY-MM-DDTHH:mm:ssZ
   
    * @param {string} timezone - timezone from IANA database as string
   
    * @param {string} locale - locale abbreviation e.g. 'de' or 'en', default: 'en'
  
    * @param {string} format - format pattern 
     
    * @returns {string} formatted string, default: 'DD.MM.YYYY HH:mm'
   
    * @example America/New_York UTC -5
    * formatStringInTZKeepTime('2019-11-10T23:00:00Z','America/New_York''):
    * 11.11.2019 00:00
     
    * @see https://day.js.org/docs/en/parse/string-format
    */
    formatStringInTZKeepTime: (
        isoFormattedString: string,
        timezone: string,
        locale?: string,
        format?: string
    ) => {
        checkIsoFormattedString(isoFormattedString)

        return dayjs(isoFormattedString)
            .tz(timezone, true)
            .locale(locale || DEFAULT_LANGUAGE)
            .format(format || 'DD.MM.YYYY HH:mm')
    },

    /**
    * cast dateTime string to dayjs, system timezone
     
    * @param {string} dateTime - date with/without time 
     
    * @returns {dayjs} dayjs object in system timezone
   
    * @example
  
    * unformattedStringToDayjs('2019-11-10T23:00:00Z'):
    * 11.11.2019 00:00
  
    * unformattedStringToDayjs('2019-11-10'):
    * 10.11.2019 00:00
  
    * !be carefully with pattern!
    * unformattedStringToDayjs('10.11.2019 23:00'):
    * 11.10.2019 00:00 
    */
    unformattedStringToDayjs: (dateTime: string) => dayjs(dateTime),
    /**
    * cast dateTime string to dayjs, system timezone
     
    * @param {string} dateTime - date with/without time 
   
    * @param {string} format - format of the input dateTime
     
    * @returns {dayjs} dayjs object in system timezone
   
    * @example
  
    * formattedStringToDayjs('10.11.2019 23:00','DD.MM.YYYY HH:mm'):
    * 10.11.2019 23:00 | 2019-11-10T22:00:00.000Z
    * 
    * formattedStringToDayjs('10A11R2019N23E00','DDAMMRYYYYNHHEmm'):
    * 10.11.2019 23:00 | 2019-11-10T22:00:00.000Z
    * 
    * @see https://day.js.org/docs/en/parse/string-format 
    */
    formattedStringToDayjs: (dateTime: string, format: string) =>
        dayjs(dateTime, format),

    /**
    * return dayjs in requested timezone but keeps local time 
    * override! use carefully!
     
    * @param {Dayjs} dateTime - dateTime as dayjs object
   
    * @param {string} timezone - timezone from IANA database as string
   
    * @returns {Dayjs} dayjs object with new timezone but same time
   
    * @example
    * overrideTimezone(dayjs('2019-11-10T23:00:00Z'),'Europe/Berlin'):
    * Nov 10 2019 23:00:00 GMT+0000 
    * 
    * dayjs(dateTime).tz(timezone, true)
    */
    overrideTimezone: (dateTime: Dayjs, timezone: string) => {
        if (!dateTime.isValid()) throw Error('no valid dayjs object')

        return dayjs(dateTime).tz(timezone, true)
    },

    /**
    * return dayjs in DEFAULT_TIMEZON but keeps local time 
    * override! use carefully!
     
    * @param {Dayjs} dateTime - dateTime as dayjs object
   
    * @returns {Dayjs} dayjs object with new timezone but same time
   
    * @example
    * overrideWithDefaultTimezone(dayjs('2019-11-10T23:00:00Z')):
    * Nov 10 2019 23:00:00 GMT+0000 
    * 
    * dayjs(dateTime).tz(DEFAULT_TIMEZONE, true)
    */
    overrideWithDefaultTimezone: (dateTime: Dayjs) => {
        if (!dateTime.isValid()) throw Error('no valid dayjs object')

        return dayjsHelper.overrideTimezone(dateTime, DEFAULT_TIMEZONE)
    },
}

const checkIsoFormattedString = (isoFormattedString: string) => {
    if (!isISOString(isoFormattedString)) throw Error('no ISO string')
    if (!dayjs(isoFormattedString).isValid())
        throw Error('no valid dayjs object')
}
