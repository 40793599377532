import { OverlayRef } from '@angular/cdk/overlay'
import { OverlayAnimatedContent } from '@shared-ui-lib/overlay/overlay-animated-content'
import { Observable, Subject } from 'rxjs'
import { filter, take } from 'rxjs/operators'

export class OverlayContentRef<C> {
    componentInstance?: OverlayAnimatedContent | null

    private _beforeClose = new Subject<void>()
    private _afterClosed = new Subject<void>()

    constructor(private overlayRef: OverlayRef) {}

    afterClosed(): Observable<void> {
        return this._afterClosed.asObservable()
    }

    beforeClose(): Observable<void> {
        return this._beforeClose.asObservable()
    }

    close() {
        this.componentInstance?.animationStateChanged
            .pipe(
                filter((event) => event.phaseName === 'start'),
                take(1)
            )
            .subscribe((state) => {
                this._beforeClose.next()
                this._beforeClose.complete()
                this.overlayRef.detachBackdrop()
            })

        this.componentInstance?.animationStateChanged
            .pipe(
                filter(
                    (event) =>
                        event.phaseName === 'done' && event.toState === 'leave'
                ),
                take(1)
            )
            .subscribe((state) => {
                this.overlayRef.dispose()
                this._afterClosed.next()
                this._afterClosed.complete()

                this.componentInstance = null
            })

        this.componentInstance?.startExitAnimation()
    }
}
