import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { ParsedAccessPeriod } from '@group-management-lib/group-management.model'
import { dayjsHelper } from '@localization-lib/date-time/dayjs/dayjsHelper'
import { Dayjs } from 'dayjs'
import { BehaviorSubject, Subscription, combineLatest, startWith } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
    selector: 'app-group-management-access-period-selection',
    templateUrl: './group-management-access-period-selection.component.html',
    styleUrls: ['./group-management-access-period-selection.component.scss'],
})
export class GroupManagementAccessPeriodSelectionComponent
    implements OnDestroy
{
    @Input()
    set accessPeriod(value: ParsedAccessPeriod | null) {
        if (value) {
            this.accessPeriod$.next(value)
        }
    }

    @Output() readonly selectedAccessPeriod =
        new EventEmitter<ParsedAccessPeriod>()

    readonly accessPeriod$ = new BehaviorSubject<ParsedAccessPeriod>({
        beginOn: null,
        endOn: null,
    })
    startDateControl = new FormControl<Dayjs | null>(null)
    endDateControl = new FormControl<Dayjs | null>(null)

    private readonly subscriptions = new Subscription()

    constructor() {
        this.subscriptions.add(
            this.accessPeriod$.subscribe((a) => {
                if (a.beginOn) {
                    this.startDateControl.setValue(a.beginOn)
                }

                if (a.endOn) {
                    this.endDateControl.setValue(a.endOn)
                }
            })
        )

        this.subscriptions.add(
            combineLatest([
                this.startDateControl.valueChanges.pipe(
                    startWith(null),
                    map((startDate) =>
                        startDate
                            ? dayjsHelper.overrideWithDefaultTimezone(startDate)
                            : null
                    )
                ),
                this.endDateControl.valueChanges.pipe(
                    startWith(null),
                    map((endDate) => {
                        if (endDate) {
                            // The end date should be inclusive, such that the group stays accessible until the end of the day.
                            // Also make sure the timezone is set to Berlin.
                            return dayjsHelper
                                .overrideWithDefaultTimezone(endDate)
                                .endOf('day')
                        }
                        return null
                    })
                ),
            ]).subscribe(([startDate, endDate]) => {
                this.selectedAccessPeriod.emit({
                    beginOn: startDate,
                    endOn: endDate,
                })
            })
        )
    }

    isValidStartDate = (d: Dayjs | null): boolean => {
        const endDate = this.endDateControl.value
        return d === null || endDate === null || d.isSameOrBefore(endDate)
    }

    isValidEndDate = (d: Dayjs | null): boolean => {
        const startDate = this.startDateControl.value
        return d === null || startDate === null || startDate.isSameOrBefore(d)
    }

    resetPeriodSelection(event: MouseEvent, formControl: FormControl) {
        event.stopImmediatePropagation()
        formControl.setValue(null)
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
