import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { Action } from '@auth-util-lib/auth.model'
import { AuthnService } from '@auth-util-lib/authn.service'
import { AuthzService } from '@auth-util-lib/authz.service'
import { ApiService } from '@env-lib/api/api.service'
import {
    loadWagonsForSelectedGroup,
    refreshBackend,
    setGroupIdToEdit,
    setGroupsFromToken,
} from '@group-management-lib/redux/group-management.actions'
import { selectGroupsIdsCheck } from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { Store, select } from '@ngrx/store'
import { OverlayContentRef } from '@shared-ui-lib/overlay/overlay-content-ref'
import { isTruthy } from '@util-lib/isTruthy'
import {
    Observable,
    Subscription,
    combineLatest,
    distinctUntilChanged,
} from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { GroupManagementCreatorComponent } from './components/group-management-creator/group-management-creator.component'
import { GroupManagementEditorComponent } from './components/group-management-editor/group-management-editor.component'
import {
    ChangeAccessPeriodRequest,
    ChangeGroupSelectionRequest,
    ChangeMembersRequest,
    ChangeWagonSelectionRequest,
    CreateGroupRequest,
    CreateGroupResponse,
    EditGroupRequest,
    GroupSource,
    MyColleaguesResponse,
    MyGroupsMetadataResponse,
    MyGroupsWithResourcesResponse,
    ParsedAccessPeriod,
    PossibleWagonsResponse,
    SelectedWagonsResponse,
} from './group-management.model'

@Injectable({
    providedIn: 'root',
})
export class GroupManagementService implements OnDestroy {
    private subscriptions = new Subscription()
    previousGroupsCheck: boolean | null = null

    private _overlayRef?: OverlayRef | null = null
    private _overlayContentRef?: OverlayContentRef<
        GroupManagementEditorComponent | GroupManagementCreatorComponent
    > | null = null

    readonly minInputLength = 3
    readonly maxInputLength = 12

    private readonly isGroupAccessControlEnabled$ =
        this.authzService.isGroupAccessControlEnabled$

    readonly isAdmin$: Observable<boolean> = this.authzService
        .hasAction$(Action.ShowUserManagement)
        .pipe(shareReplay(1))

    /**
     * Managing groups is allowed if groupAccessControl is disabled.
     * If it is enabled, the user must be admin.
     */
    readonly isGroupManagementAllowed$: Observable<boolean> = combineLatest([
        this.isGroupAccessControlEnabled$,
        this.isAdmin$,
    ]).pipe(
        map(
            ([isGroupAccessControlEnabled, isAdmin]) =>
                !isGroupAccessControlEnabled || isAdmin
        ),
        shareReplay(1)
    )

    /**
     * Assigning individual users or access periods to groups is only possible if groupAccessControl is enabled and the user is admin.
     */
    readonly isAdminInGacClient$: Observable<boolean> = combineLatest([
        this.isGroupAccessControlEnabled$,
        this.isAdmin$,
    ]).pipe(
        map(
            ([isGroupAccessControlEnabled, isAdmin]) =>
                isGroupAccessControlEnabled && isAdmin
        ),
        shareReplay(1)
    )

    constructor(
        private api: ApiService,
        private http: HttpClient,
        private authzService: AuthzService,
        private authnService: AuthnService,
        private store: Store<GroupManagementState>,
        private overlay: Overlay
    ) {
        // token - load (reacts to changes)
        this.subscriptions.add(
            this.authzService
                .getClaim$('group_ids')
                .pipe(
                    map((groupIds) => {
                        if (groupIds === null || typeof groupIds === 'number') {
                            return []
                        }
                        return groupIds.split(',').filter(isTruthy)
                    }),
                    distinctUntilChanged()
                )
                .subscribe((groupsFromToken) => {
                    this.store.dispatch(
                        setGroupsFromToken({
                            groupsFromToken: groupsFromToken,
                        })
                    )
                })
        )

        // listen to check group ids changes
        // if changes from valid to invalid, then
        // reload either token or backend depending of
        // which source has triggered the check
        this.subscriptions.add(
            this.store
                .pipe(select(selectGroupsIdsCheck))
                .subscribe(({ check, source }) => {
                    if (this.previousGroupsCheck === true && check === false) {
                        if (source === GroupSource.BACKEND) {
                            this.authnService.refreshAccessToken()
                        }

                        if (source === GroupSource.TOKEN) {
                            this.store.dispatch(
                                refreshBackend({ refresh: true })
                            )
                        }
                        // in case someone else added or deleted a group
                    } else if (
                        this.previousGroupsCheck === false &&
                        check === false &&
                        source === GroupSource.BACKEND
                    ) {
                        this.authnService.refreshAccessToken()
                    }
                    this.previousGroupsCheck = check
                })
        )
    }

    getMyGroupsMetaData(): Observable<MyGroupsMetadataResponse> {
        const url = `${this.api.auth.backendUrl}/v2/users/me/groups/metadata`
        return this.http.get<MyGroupsMetadataResponse>(url)
    }

    getMyColleagues(): Observable<MyColleaguesResponse> {
        const url = `${this.api.auth.backendUrl}/v2/users/me/colleagues`
        return this.http.get<MyColleaguesResponse>(url)
    }

    private getOverlayConfig(): OverlayConfig {
        return new OverlayConfig({
            height: 'calc(100% - 56px)', // HEADER
            width: '45%',
            maxWidth: '720px',
            minWidth: '440px',
            positionStrategy: this.overlay.position().global().right().bottom(),
            scrollStrategy: this.overlay.scrollStrategies.noop(),
        })
    }

    openLayer(groupId?: string) {
        this.closeOldLayerIfPresent(groupId)
    }

    // If old overlay is present, try to close it. Old Overlay might open a popup to ensure that no changes are lost.
    // If the user decides to keep the old overlay, we might never open a new overlay.
    private closeOldLayerIfPresent(groupId?: string) {
        const overlayComponent = this._overlayContentRef?.componentInstance

        if (overlayComponent instanceof GroupManagementCreatorComponent) {
            if (groupId == null) {
                return
            }

            overlayComponent.requestClose(() => this.openNewLayer(groupId))
        } else if (overlayComponent instanceof GroupManagementEditorComponent) {
            if (groupId === overlayComponent.groupId) {
                return
            }

            overlayComponent.requestClose(() => this.openNewLayer(groupId))
        } else {
            this.openNewLayer(groupId)
        }
    }

    private openNewLayer(groupId?: string) {
        this.overlay.create(this.getOverlayConfig())
        this._overlayRef = this.overlay.create(this.getOverlayConfig())
        this._overlayContentRef = new OverlayContentRef(this._overlayRef)

        let portal
        if (groupId) {
            this.store.dispatch(
                setGroupIdToEdit({
                    groupIdToEdit: groupId,
                })
            )
            this.store.dispatch(loadWagonsForSelectedGroup({ groupId }))
            portal = new ComponentPortal(GroupManagementEditorComponent)
        } else {
            portal = new ComponentPortal(GroupManagementCreatorComponent)
        }

        const portalRef = this._overlayRef.attach(portal)
        this._overlayContentRef.componentInstance = portalRef.instance
    }

    closeLayer() {
        this._overlayRef = null
        this._overlayContentRef?.close()
        this._overlayContentRef = null
    }

    getMyGroupsWithResources(): Observable<MyGroupsWithResourcesResponse> {
        const url = `${this.api.auth.backendUrl}/v2/users/me/groups`
        return this.http.get<MyGroupsWithResourcesResponse>(url)
    }

    createGroup(request: CreateGroupRequest): Observable<CreateGroupResponse> {
        return this.http.post<CreateGroupResponse>(
            `${this.api.auth.backendUrl}/v2/groups`,
            request
        )
    }

    editGroup(groupId: string, request: EditGroupRequest) {
        return this.http.put(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}`,
            request
        )
    }

    deleteGroup(groupId: string) {
        const url = `${this.api.auth.backendUrl}/v2/groups/${groupId}`
        return this.http.delete(url)
    }

    changeGroupSelection(
        changeGroupSelectionRequest: ChangeGroupSelectionRequest
    ): Observable<MyGroupsMetadataResponse> {
        return this.http.put<MyGroupsMetadataResponse>(
            `${this.api.auth.backendUrl}/v2/users/me/groups/selections`,
            changeGroupSelectionRequest
        )
    }

    fetchAllWagons(): Observable<PossibleWagonsResponse> {
        return this.http.get<PossibleWagonsResponse>(
            `${this.api.auth.backendUrl}/v2/users/me/wagons`
        )
    }

    loadWagonsForSelectedGroup(
        groupId: string
    ): Observable<SelectedWagonsResponse> {
        return this.http.get<SelectedWagonsResponse>(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}/wagons`
        )
    }

    changeSelectedWagonsForGroup(
        groupId: string,
        changeWagonSelectionRequest: ChangeWagonSelectionRequest
    ): Observable<void> {
        return this.http.put<void>(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}/wagons`,
            changeWagonSelectionRequest
        )
    }

    changeMembersGroup(
        groupId: string,
        changeMembersRequest: ChangeMembersRequest
    ): Observable<void> {
        return this.http.put<void>(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}/members`,
            changeMembersRequest
        )
    }

    changeAccessPeriod(
        groupId: string,
        accessPeriod: ParsedAccessPeriod
    ): Observable<void> {
        const request = {
            beginOn: accessPeriod.beginOn?.toISOString(),
            endOn: accessPeriod.endOn?.toISOString(),
        } as ChangeAccessPeriodRequest

        return this.http.put<void>(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}/access-period`,
            request
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
