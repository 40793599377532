<ng-container *ngIf="responseType$ | async as responseType">
    <div class="response-container">
        <mat-icon
            class="icon pb-4"
            [ngClass]="responseType"
            [svgIcon]="icon$ | async"
        ></mat-icon>
        <div class="title text-subtitle-1 pb-2">
            <span
                [translate]="(title$ | async)?.text"
                [params]="(title$ | async)?.parameter"
                data-qa="system-response_title"
            ></span>
        </div>
        <div class="grid gap-y-6">
            <div class="text-body-1">
                <span
                    [translate]="(message$ | async)?.text"
                    [params]="(message$ | async)?.parameter"
                    data-qa="system-response_message"
                ></span>
            </div>
            <ng-content></ng-content>

            <ng-container
                *ngIf="
                    (flatPrimaryButtonText$ | async) ||
                    (basicPrimaryButtonText$ | async)
                "
            >
                <app-button-group alignment="center">
                    <ng-container
                        *ngIf="
                            basicPrimaryButtonText$
                                | async as basicPrimaryButtonText
                        "
                    >
                        <app-button
                            buttonType="basic-primary"
                            [status]="basicPrimaryButtonState$ | async"
                            [icon]="basicPrimaryButtonIcon$ | async"
                            [iconPosition]="
                                basicPrimaryButtonIconPosition$ | async
                            "
                            (buttonClick)="handleTextPrimaryButtonClick()"
                            >{{
                                basicPrimaryButtonText | translate
                            }}</app-button
                        >
                    </ng-container>
                    <ng-container
                        *ngIf="
                            flatPrimaryButtonText$
                                | async as flatPrimaryButtonText
                        "
                    >
                        <app-button
                            buttonType="flat-primary"
                            [status]="flatPrimaryButtonState$ | async"
                            [icon]="flatPrimaryButtonIcon$ | async"
                            [iconPosition]="
                                flatPrimaryButtonIconPosition$ | async
                            "
                            (buttonClick)="handleFilledPrimaryButtonClick()"
                            >{{ flatPrimaryButtonText | translate }}</app-button
                        >
                    </ng-container>
                </app-button-group>
            </ng-container>
        </div>
    </div>
</ng-container>
