import { Pipe, PipeTransform } from '@angular/core'
import { defaultDate } from '@localization-lib/date-time/dayjs/dayjsHelper'
import { Dayjs } from 'dayjs'

export const DEFAULT_TIMEZONE = 'Europe/Berlin'

@Pipe({
    name: 'dateInDefaultTimezone',
})
export class DateInDefaultTimezonePipe implements PipeTransform {
    transform(
        dateTime: Date | string | Dayjs | undefined,
        format?: string
    ): string | undefined {
        return dateTime
            ? defaultDate(dateTime).format(format ?? 'DD.MM.YYYY HH:mm')
            : undefined
    }
}
