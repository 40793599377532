import {
    Groups,
    MinimalGroup,
    ParsedAccessPeriod,
} from '@group-management-lib/group-management.model'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
    GroupManagementState,
    groupManagementFeatureKey,
} from './group-management.state'

// #######################
// General
// #######################

export const selectGroupManagement =
    createFeatureSelector<GroupManagementState>(groupManagementFeatureKey)

export const selectGroupsFromToken = createSelector(
    selectGroupManagement,
    (state) => state.groupsFromToken
)

export const selectGroupsFromBackend = createSelector(
    selectGroupManagement,
    (state) =>
        state.groupsFromBackend
            ? [...state.groupsFromBackend]?.sort((a, b) =>
                  a.name.localeCompare(b.name)
              )
            : null
)

export const selectGroupsFromBackendLoading = createSelector(
    selectGroupManagement,
    (state) => state.groupsFromBackendLoading
)

export const selectGroupsFromBackendError = createSelector(
    selectGroupManagement,
    (state) => state.groupsFromBackendError
)

export const selectGroupsIdsCheck = createSelector(
    selectGroupManagement,
    (state) => ({
        check: state.checkGroupsEqual,
        source: state.lastGroupSource,
    })
)

export const selectGroupOptions = createSelector(
    selectGroupsFromBackend,
    (groups) => {
        const groupsWithoutDefault = groups?.filter((group) => !group.isDefault)

        if (groupsWithoutDefault && groupsWithoutDefault.length > 0) {
            return groupsWithoutDefault
        }

        return []
    }
)

export const selectIsMemberOfDefaultGroup = createSelector(
    selectGroupManagement,
    selectGroupsFromBackend,
    (state, groups) => !!groups && groups.some((group) => group.isDefault)
)

export const hasGroupLoadingError = createSelector(
    selectGroupManagement,
    (state) => state.groupsFromBackendError !== null
)

export const selectRefreshBackend = createSelector(
    selectGroupManagement,
    (state) => state.refreshBackend
)

// #######################
// Group Management Page
// #######################

export const selectGroupsWithResources = createSelector(
    selectGroupManagement,
    (state) => state.groupsWithResources
)

export const selectGroupsWithResourcesLoading = createSelector(
    selectGroupManagement,
    (state) => state.groupsWithResourcesLoading
)

export const selectGroupsWithResourcesError = createSelector(
    selectGroupManagement,
    (state) => state.groupsWithResourcesError
)

// #######################
// Dropdown / Header
// #######################

export const selectSelectedGroups = createSelector(
    selectGroupManagement,
    (state) => {
        if (state.checkGroupsEqual) {
            return state.groupsFromBackend
                ?.filter((group) => group.isSelected && !group.isDefault)
                .sort((a, b) => a.name.localeCompare(b.name))
        }

        return null
    }
)

export const selectDefaultGroup = createSelector(
    selectGroupManagement,
    (state) => {
        if (state.checkGroupsEqual) {
            return state.groupsFromBackend?.filter((group) => group.isDefault)
        }

        return null
    }
)

export const selectSelectedOrDefaultGroups = createSelector(
    selectGroupManagement,
    selectSelectedGroups,
    selectDefaultGroup,
    (state, selectedGroups, defaultGroup) => {
        if (state.checkGroupsEqual) {
            if (selectedGroups && selectedGroups.length > 0) {
                return selectedGroups
            } else {
                return defaultGroup
            }
        }

        return null
    }
)

export const selectSelectedGroupIds = createSelector(
    selectGroupManagement,
    selectSelectedOrDefaultGroups,
    (state, selectedGroups) => {
        if (state.checkGroupsEqual && Array.isArray(selectedGroups)) {
            const groupsWithoutDefault = selectedGroups.filter(
                (group) => !group.isDefault
            )
            if (groupsWithoutDefault.length > 0) {
                return groupsWithoutDefault.map((group) => group.id).join(',')
            }
            const defaultGroup = selectedGroups.find((group) => group.isDefault)
            if (defaultGroup) {
                return defaultGroup.id
            }
            return state.groupsFromToken?.join(',') ?? ''
        }
        return null
    }
)

export const selectChangeGroupSelectionLoading = createSelector(
    selectGroupManagement,
    (state) => state.changeGroupSelectionLoading
)

export const selectChangeGroupSelectionError = createSelector(
    selectGroupManagement,
    (state) => state.changeGroupSelectionError
)

// #######################
// General Group Overlay
// #######################

export const selectPossibleWagonsForUser = createSelector(
    selectGroupManagement,
    (state) =>
        state.possibleWagonsForUser ? [...state.possibleWagonsForUser] : null
)

export const selectPossibleWagonsForUserLoading = createSelector(
    selectGroupManagement,
    (state) => state.possibleWagonsForUserLoading
)

export const selectPossibleWagonsForUserError = createSelector(
    selectGroupManagement,
    (state) => state.possibleWagonsForUserError
)

export const selectCreateGroupLoading = createSelector(
    selectGroupManagement,
    (state) => state.createGroupLoading
)

export const selectCreateGroupError = createSelector(
    selectGroupManagement,
    (state) => state.createGroupError
)

export const selectChangeSelectedWagonsForGroupLoading = createSelector(
    selectGroupManagement,
    (state) => state.changeSelectedWagonsForGroupLoading
)

export const selectChangeSelectedWagonsForGroupError = createSelector(
    selectGroupManagement,
    (state) => state.changeSelectedWagonsForGroupError
)

export const selectChangeMembersOfGroupLoading = createSelector(
    selectGroupManagement,
    (state) => state.changeMembersOfGroupLoading
)

export const selectChangeMembersOfGroupError = createSelector(
    selectGroupManagement,
    (state) => state.changeMembersOfGroupError
)

// #######################
// Group Editor
// #######################

export const selectGroupIdToEdit = createSelector(
    selectGroupManagement,
    (state) => state.groupIdToEdit
)

export const selectGroupToEdit = createSelector(
    selectGroupManagement,
    (state) => {
        // these are only the groups you are a member of
        const groupFromMetadata: MinimalGroup | null =
            state.groupsFromBackend?.find(
                (group) => group.id === state.groupIdToEdit
            ) ?? null

        // for admin use cases, we need to get all groups
        const groupFromResources: Groups | null =
            state.groupsWithResources?.find(
                (group) => group.groupId === state.groupIdToEdit
            ) ?? null
        const minimalGroupFromResources: MinimalGroup | null =
            groupFromResources
                ? {
                      id: groupFromResources.groupId,
                      name: groupFromResources.groupName,
                      isDefault: groupFromResources.isDefault,
                  }
                : null

        return groupFromMetadata || minimalGroupFromResources || null
    }
)

export const selectWagonsForSelectedGroup = createSelector(
    selectGroupManagement,
    (state) => state.wagonsForSelectedGroup
)

export const selectWagonsForSelectedGroupLoading = createSelector(
    selectGroupManagement,
    (state) => state.wagonsForSelectedGroupLoading
)

export const selectWagonsForSelectedGroupError = createSelector(
    selectGroupManagement,
    (state) => state.wagonsForSelectedGroupError
)

export const selectEditGroupLoading = createSelector(
    selectGroupManagement,
    (state) => state.editGroupLoading
)

export const selectEditGroupError = createSelector(
    selectGroupManagement,
    (state) => state.editGroupError
)

export const selectDeleteGroupLoading = createSelector(
    selectGroupManagement,
    (state) => state.deleteGroupLoading
)

export const selectDeleteGroupError = createSelector(
    selectGroupManagement,
    (state) => state.deleteGroupError
)

export const selectColleagues = createSelector(
    selectGroupManagement,
    (state) => state.colleagues
)

export const selectUserSelectionForNewGroup = createSelector(
    selectColleagues,
    (colleagues) =>
        colleagues.map((c) => ({
            userId: c.id,
            firstName: c.firstName,
            lastName: c.lastName,
            isMember: false,
            isAdmin: c.isAdmin,
        }))
)

export const selectColleaguesOfSelectedGroup = createSelector(
    selectGroupIdToEdit,
    selectGroupsWithResources,
    selectColleagues,
    (groupId, groups, colleagues) => {
        const selectedGroup = groups?.find((group) => group.groupId === groupId)
        const members = selectedGroup?.memberDetails?.members ?? []
        return colleagues.map((c) => ({
            userId: c.id,
            firstName: c.firstName,
            lastName: c.lastName,
            isMember: members.some((m) => m.userId === c.id),
            isAdmin: c.isAdmin,
        }))
    }
)

export const selectAccessPeriodOfSelectedGroup = createSelector(
    selectGroupIdToEdit,
    selectGroupsWithResources,
    (groupId, groups) => {
        const selectedGroup = groups?.find((group) => group.groupId === groupId)
        return selectedGroup?.period
            ? ({
                  beginOn: selectedGroup.period.beginOn,
                  endOn: selectedGroup.period.endOn,
              } as ParsedAccessPeriod)
            : null
    }
)
