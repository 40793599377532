import { Action, Unprotected } from '@auth-util-lib/auth.model'
import { MenuEntry } from '@shared-util-lib/models/menu-entry.model'

interface SideMenuSections {
    Services: MenuEntry[]
    Marketplace: MenuEntry[]
    Admin: MenuEntry[]
    Support: MenuEntry[]
}

export const sideMenuSectionsOrder: (keyof SideMenuSections)[] = [
    'Services',
    'Marketplace',
    'Admin',
    'Support',
]

export const sideMenuSections: SideMenuSections = {
    Services: [
        {
            action: Action.ShowContracts,
            label: 'Contract Management',
            route: '/contracts',
        },
        {
            action: Action.ShowEta,
            label: 'ETA Calculation',
            route: '/eta',
        },
        {
            action: Action.ShowFleet,
            label: 'Fleet Management',
            route: '/fleet/overview',
        },
        {
            action: Action.ShowPerformance,
            label: 'Idle Times',
            route: '/idle-times',
        },
        {
            action: Action.ShowWagonTracking,
            label: 'Wagon Tracking',
            route: '/wagon-tracking',
        },
        {
            action: Action.ShowDispatchManagement,
            label: 'Dispatch Management',
            route: '/dispatch-management',
        },
    ],
    Marketplace: [
        {
            action: Unprotected,
            label: 'FastTrack',
            route: '/fast-track/locations',
        },
        {
            action: Action.ShowWagonHire,
            label: 'Wagon Hire',
            route: '/wagon-hire',
        },
    ],
    Admin: [
        {
            action: Unprotected,
            label: 'Account Settings',
            route: '/account',
        },
        {
            action: Unprotected,
            label: 'Group Management',
            route: '/group-management',
        },
        {
            action: Action.ShowUserManagement,
            label: 'Data Forwarding',
            route: '/data-forwarding',
        },
        {
            action: Action.ShowClientManagement,
            label: 'Client Management',
            route: '/client-management',
        },
        {
            action: Action.ShowUserManagement,
            label: 'User Management',
            route: '/user-management',
        },
        {
            action: Action.ShowSalesCare,
            label: 'Sales Support',
            route: '/sales-care',
        },
        {
            action: Action.ShowAdminCenter,
            label: 'Admincenter',
            route: '/administration',
        },
    ],
    Support: [
        {
            action: Unprotected,
            label: 'traigo Updates',
            route: '/traigo-updates',
        },
        {
            action: Unprotected,
            label: 'Service Desk',
            route: '/legal/contact',
        },
    ],
}
