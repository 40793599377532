import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { TermsConditionsContentDeComponent } from '@platform-lib/legal/terms-conditions/terms-conditions-content/terms-conditions-content-de.component'
import { TermsConditionsContentEnComponent } from '@platform-lib/legal/terms-conditions/terms-conditions-content/terms-conditions-content-en.component'
import { BackToTopModule } from '@shared-ui-lib/back-to-top/back-to-top.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { ExpandableModule } from '@shared-ui-lib/expandable/expandable.module'
import { PageModule } from '@shared-ui-lib/page/page.module'
import { TermsConditionsDialogComponent } from './terms-conditions-dialog/terms-conditions-dialog.component'
import { TermsConditionsComponent } from './terms-conditions-overview/terms-conditions-overview.component'

@NgModule({
    declarations: [
        TermsConditionsComponent,
        TermsConditionsContentDeComponent,
        TermsConditionsContentEnComponent,
        TermsConditionsDialogComponent,
    ],
    imports: [
        BackToTopModule,
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        MatDialogModule,
        PageModule,
        ExpandableModule,
        MatIconModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationModule,
    ],
})
export class TermsConditionsModule {}
